import React from "react"
import {Helmet} from "react-helmet";
import NavBar from "../containers/NavBar";
import Footer from "../containers/Footer";

export default function Index({children}) {
    return (
        <div id='root' className="bg-gray-800 overflow-hidden ">
            <Helmet htmlAttributes={{
                lang: 'en',
            }}>
                <script defer src='https://static.cloudflareinsights.com/beacon.min.js'
                        data-cf-beacon='{"token": "ab8cdedb2dd8440b913c525b04b8845d"}'/>
                <title>Pratik Daigavane | Portfolio</title>
                <meta name="title" content="Pratik Daigavane | Portfolio"/>
                <meta name="description"
                      content="I am a Software Engineer Intern having a knack for creating highly scalable and distributed systems."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://pratikd.in"/>
                <meta property="og:title" content="Pratik Daigavane | Portfolio"/>
                <meta property="og:description"
                      content="I am a Software Engineer Intern having a knack for creating highly scalable and distributed systems."/>
                <meta property="og:image" content="https://cdn.pratikd.in/banner.png"/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content="https://pratikd.in/"/>
                <meta property="twitter:title" content="Pratik Daigavane | Portfolio"/>
                <meta property="twitter:description"
                      content="I am a Software Engineer Intern having a knack for creating highly scalable and distributed systems."/>
                <meta property="twitter:image" content="https://cdn.pratikd.in/banner.png"/>
            </Helmet>
            <NavBar/>
            <div className='relative mt-20 overflow-hidden'>
                {/*<div className="hidden sm:block sm:absolute sm:inset-0">*/}
                {/*    <div*/}
                {/*        className="dots absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0">*/}
                {/*        hello*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="relative">
                    <main className="mt-6 sm:mt-12 md:mt-16 lg:mt-18">
                        <div className="mx-auto max-w-screen-xl">
                            {children}
                        </div>
                    </main>
                </div>
            </div>
            <Footer/>
        </div>
    )
}