import React from 'react'
import {Link} from "gatsby";


const MobileNavBar = ({visible, toggle, data}) => {

    function getNavbarItems(data) {
        return data.allNavbarJson.edges.map(item =>
            <Link
                key={item.node.id}
                className="flex px-3 py-2 rounded-md text-base font-medium text-gray-200 hover:text-gray-100 hover:bg-gray-600 focus:outline-none focus:text-gray-100 focus:bg-gray-500 transition duration-150 ease-in-out"
                to={item.node.mobilePath? item.node.mobilePath:item.node.path}
                role="menuitem"
                onClick={() => toggle(!visible)}
            >
                {item.node.name}
            </Link>
        )
    }

    return (
        <div
            className={`${visible ? "duration-150 ease-out opacity-100 scale-100" : "duration-100 ease-in opacity-0 invisible scale-95"}  origin-top-right absolute top-0 p-2 inset-x-0 transition transform lg:hidden`}>
            <div className="rounded-lg bg-gray-800">
                <div role="menu" aria-orientation="vertical" aria-labelledby="main-menu"
                     className="bg-gray-900 rounded-lg overflow-hidden">
                    <div className="px-5 pt-4 flex items-center justify-end">
                        <div className="-mr-2">
                            <button onClick={() => toggle(!visible)} type="button" aria-label="Close menu"
                                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                                <svg stroke="currentColor" fill="none" viewBox="0 0 24 24"
                                     className="h-6 w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col space-y-1 px-2 pt-2 pb-3">
                        <>
                            {getNavbarItems(data)}
                        </>
                    </div>
                    <div>
                        <a target="_blank"
                           rel="noreferrer"
                           href={data.contentJson.calendlyUrl}
                           className="block w-full px-5 py-3 text-center font-medium text-gray-200 bg-indigo-700 hover:bg-indigo-600 hover:text-gray-200 focus:outline-none focus:bg-indigo-600 focus:text-gray-100 transition duration-150 ease-in-out">
                            <span role="img" aria-label="Coffee Mug">☕️</span>Let's
                            Talk
                        </a></div>
                </div>
            </div>
        </div>
    )
}

export default MobileNavBar