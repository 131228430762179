import React from 'react';

function Button({url, children, ...otherProps}) {
    return (
        <a href={url}
           className="inline-flex items-center px-4 py-2 border border-transparent text-lg leading-5 font-medium rounded-md text-white bg-indigo-600 hover:text-white hover:bg-indigo-700 focus:outline-none focus:shadow-outline-gray focus:border-indigo-600 active:bg-indigo-600 transition duration-150 ease-in-out" {...otherProps}>
            {children}
        </a>
    );
}

export default Button;